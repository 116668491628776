// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  encrypt: path(ROOTS_DASHBOARD, '/convert-subtitle'),
  decrypt: path(ROOTS_DASHBOARD, '/decrypt-subtitle'),
  invoices: path(ROOTS_DASHBOARD, '/invoices'),
  setting: path(ROOTS_DASHBOARD, '/setting'),
  sendNotification: path(ROOTS_DASHBOARD, '/send-notification'),
  tags: path(ROOTS_DASHBOARD, '/tags'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
  },
  movies: {
    root: path(ROOTS_DASHBOARD, '/movies'),
    list: path(ROOTS_DASHBOARD, '/movies/list'),
    new: path(ROOTS_DASHBOARD, '/movies/new'),
  },
  series: {
    root: path(ROOTS_DASHBOARD, '/series'),
    list: path(ROOTS_DASHBOARD, '/series/list'),
    new: path(ROOTS_DASHBOARD, '/series/new'),
  },
  soccer: {
    root: path(ROOTS_DASHBOARD, '/soccer'),
    list: path(ROOTS_DASHBOARD, '/soccer/list'),
    new: path(ROOTS_DASHBOARD, '/soccer/new'),
  },
  liveTv: {
    root: path(ROOTS_DASHBOARD, '/live-tv'),
    list: path(ROOTS_DASHBOARD, '/live-tv/list'),
    new: path(ROOTS_DASHBOARD, '/live-tv/new'),
  },
  promoCodes: {
    root: path(ROOTS_DASHBOARD, '/promo-codes'),
    list: path(ROOTS_DASHBOARD, '/promo-codes/list'),
    new: path(ROOTS_DASHBOARD, '/promo-codes/new'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
