// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  ecommerce: getIcon('ic_ecommerce'),
  encrypt: getIcon('ic_encrypt'),
  movie: getIcon('ic_movie'),
  setting: getIcon('ic_setting'),
  notification: getIcon('ic_notification'),
  tags: getIcon('ic_tags'),
  soccer: getIcon('ic_soccer'),
  series: getIcon('ic_series'),
};

const navConfig = [
  {
    subheader: 'general',
    items: [
      { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // { title: 'Invoices', path: PATH_DASHBOARD.invoices, icon: ICONS.ecommerce },
    ],
  },

  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'list', path: PATH_DASHBOARD.user.list },
          { title: 'create', path: PATH_DASHBOARD.user.newUser },
        ],
      },

      // MANAGEMENT : Movie & Series
      {
        title: 'Movies',
        path: PATH_DASHBOARD.movies.root,
        icon: ICONS.movie,
        children: [
          { title: 'list', path: PATH_DASHBOARD.movies.list },
          { title: 'create', path: PATH_DASHBOARD.movies.new },
        ],
      },
      {
        title: 'Series',
        path: PATH_DASHBOARD.series.root,
        icon: ICONS.series,
        children: [
          { title: 'list', path: PATH_DASHBOARD.series.list },
          { title: 'create', path: PATH_DASHBOARD.series.new },
        ],
      },
      { title: 'Edit Tags', path: PATH_DASHBOARD.tags, icon: ICONS.tags },
      {
        title: 'Soccer',
        path: PATH_DASHBOARD.soccer.root,
        icon: ICONS.soccer,
        children: [
          { title: 'list', path: PATH_DASHBOARD.soccer.list },
          { title: 'create', path: PATH_DASHBOARD.soccer.new },
        ],
      },
      {
        title: 'TV Channels',
        path: PATH_DASHBOARD.liveTv.root,
        icon: ICONS.series,
        children: [
          { title: 'list', path: PATH_DASHBOARD.liveTv.list },
          { title: 'create', path: PATH_DASHBOARD.liveTv.new },
        ],
      },
      {
        title: 'Promo Codes',
        path: PATH_DASHBOARD.promoCodes.root,
        icon: ICONS.tags,
        children: [
          { title: 'list', path: PATH_DASHBOARD.promoCodes.list },
          { title: 'create', path: PATH_DASHBOARD.promoCodes.new },
        ],
      },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      { title: 'Settings', path: PATH_DASHBOARD.setting, icon: ICONS.setting },
      {
        title: 'Send Notification',
        path: PATH_DASHBOARD.sendNotification,
        icon: ICONS.notification,
      },
      // { title: 'Encrypt Subtitle', path: PATH_DASHBOARD.encrypt, icon: ICONS.encrypt },
      // { title: 'Decrypt Subtitle', path: PATH_DASHBOARD.decrypt, icon: ICONS.encrypt },
    ],
  },
];

export default navConfig;
