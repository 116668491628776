import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  // const theme = useTheme();
  // const PRIMARY_MAIN = theme.palette.primary.main;

  const logo = (
    <Box sx={{ width: 60, height: 60, ...sx }}>
      <img src="/logo/logo.png" alt="logo" />
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        className="iconify iconify--emojione"
        width="100%"
        height="100%"
        viewBox="0 0 64 64"
      >
        <path
          d="M62 52c0 5.5-4.5 10-10 10H12C6.5 62 2 57.5 2 52V12C2 6.5 6.5 2 12 2h40c5.5 0 10 4.5 10 10v40z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M41.7 46H47L35 14h-6L17 46h5.3l4.2-11.2h11.1L41.7 46zM28.3 30l3.7-9.9 3.7 9.9h-7.4z"
          fill="#fff"
        />
      </svg> */}
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
