import { createContext, useReducer, useMemo, useEffect } from 'react';
import { API } from '../config';

const initialState = {
  isAuthenticated: false,
  user: null,
  isInitialized: false,
};

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

const handlers = {
  LOGIN: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    isInitialized: true,
    user: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem('token');

        if (!token) throw new Error('No token');

        await refreshUser(token);
      } catch (err) {
        logout();
      }
    })();
  }, []);

  const refreshUser = async (token) => {
    const res = await fetch(`${API.url}/users/me`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const json = await res.json();

    if (!res.ok) {
      throw new Error(json?.error?.message);
    }

    if (!(json?.user?.role?.type || '').includes('admin')) {
      // eslint-disable-next-line prefer-promise-reject-errors
      throw new Error('Invalid Indentifier or Password');
    }

    localStorage.setItem('token', json.jwt);
    dispatch({
      type: 'LOGIN',
      payload: {
        isAuthenticated: true,
        user: json.user,
      },
    });
  };

  const login = async (identifier, password) => {
    const res = await fetch(`${API.url}/auth/local`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ identifier, password }),
    });

    const json = await res.json();

    if (!res.ok) {
      throw new Error(json?.error?.message);
    }

    // to know if the user is admin
    // login don't response user role
    await refreshUser(json.jwt);
  };

  const logout = async () => {
    localStorage.removeItem('token');
    dispatch({ type: 'LOGOUT' });
  };

  const value = useMemo(
    () => ({
      ...state,
      login,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthProvider };
