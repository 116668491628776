import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

export default function RoleBasedGuard({ children }) {
  const { user } = useAuth();

  if (user?.role?.type !== 'super_admin') {
    return <Navigate to="404" />;
  }

  return <>{children}</>;
}
