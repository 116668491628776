import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

//  ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'convert-subtitle', element: <EncryptSubtitle key="encrypt" /> },
        {
          path: 'decrypt-subtitle',
          element: (
            <RoleBasedGuard>
              <EncryptSubtitle key="decrypt" isDecrypt />
            </RoleBasedGuard>
          ),
        },
        { path: 'invoices', element: <PurchaseList /> },
        { path: 'setting', element: <Setting /> },

        { path: 'tags', element: <Tags /> },
        { path: 'send-notification', element: <SendNotification /> },

        {
          path: 'movies',
          children: [
            { element: <Navigate to="/dashboard/movies/list" replace />, index: true },
            { path: 'list', element: <PostList type="movie" key="movie" /> },
            { path: 'new', element: <PostCreate type="movie" key="movie" /> },
            { path: ':id/edit', element: <PostCreate type="movie" key="movie" /> },
          ],
        },
        {
          path: 'series',
          children: [
            { element: <Navigate to="/dashboard/series/list" replace />, index: true },
            { path: 'list', element: <PostList type="series" key="series" /> },
            { path: 'new', element: <PostCreate type="series" key="series" /> },
            { path: ':id/edit', element: <PostCreate type="series" key="series" /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserCreate /> },
          ],
        },
        {
          path: 'soccer',
          children: [
            { element: <Navigate to="/dashboard/soccer/list" replace />, index: true },
            { path: 'list', element: <SoccerList /> },
            { path: 'new', element: <SoccerCreate /> },
            { path: ':id/edit', element: <SoccerCreate /> },
          ],
        },
        {
          path: 'live-tv',
          children: [
            { element: <Navigate to="/dashboard/live-tv/list" replace />, index: true },
            { path: 'list', element: <SoccerList isTvChannel /> },
            { path: 'new', element: <SoccerCreate isTvChannel /> },
            { path: ':id/edit', element: <SoccerCreate isTvChannel /> },
          ],
        },
        {
          path: 'promo-codes',
          children: [
            { element: <Navigate to="/dashboard/promo-codes/list" replace />, index: true },
            { path: 'list', element: <PromoCodeList /> },
            { path: 'new', element: <PromoCodeCreate /> },
            { path: ':id/edit', element: <PromoCodeCreate /> },
          ],
        },
      ],
    },

    {
      path: '/',
      element: <Navigate to="/dashboard" replace />,
    },
    { path: '404', element: <NotFound /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COM PONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const PostList = Loadable(lazy(() => import('../pages/dashboard/PostList')));
const PostCreate = Loadable(lazy(() => import('../pages/dashboard/PostCreate')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const SoccerList = Loadable(lazy(() => import('../pages/dashboard/SoccerList')));
const SoccerCreate = Loadable(lazy(() => import('../pages/dashboard/SoccerCreate')));
const PromoCodeList = Loadable(lazy(() => import('../pages/dashboard/PromoCodeList')));
const PromoCodeCreate = Loadable(lazy(() => import('../pages/dashboard/PromoCodeCreate')));

const EncryptSubtitle = Loadable(lazy(() => import('../pages/dashboard/EncryptSubtitle')));
const PurchaseList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const Setting = Loadable(lazy(() => import('../pages/dashboard/Setting')));
const Tags = Loadable(lazy(() => import('../pages/dashboard/Tags')));
const SendNotification = Loadable(lazy(() => import('../pages/dashboard/SendNotification')));

const NotFound = Loadable(lazy(() => import('../pages/Page404')));
